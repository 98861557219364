import { template as template_1c70b810413e4a41b64f7073e7932a0d } from "@ember/template-compiler";
export default template_1c70b810413e4a41b64f7073e7932a0d(`
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
